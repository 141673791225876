<template>
  <svg
    viewBox="0 0 15 25"
    class="scroll"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="14" height="24" rx="7" />
    <circle id="wheel" cx="7.5" cy="8.5" r="1.5" />
  </svg>
</template>

<style lang="scss" scope>
.scroll {
  display: block;
  width: 16px;
  height: 50px;
  transform: translateX(-50%);

  rect {
    stroke: var(--color-white);
  }

  #wheel {
    position: absolute;
    fill: var(--color-white);
    animation-name: moveDown;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(px);
  }
  100% {
    transform: translateY(5px);
  }
}
</style>
